module.exports = {
    get: (url, callback) => {
        ajax(url, callback, 'GET');
    }
};


function ajax(url, callback, method) {
    var xhr = new XMLHttpRequest();
    xhr.open(method || 'GET', url);
    xhr.onload = function() {
        if (xhr.status === 200) {
            callback(xhr.responseText);
        }
    };
    xhr.send();
}
