const { get } = require('./ajax');

const searchCtr = document.createElement('div');
const searchBox = document.createElement('input');
const results = document.createElement('div');
const searchButton = document.createElement('button');
searchButton.innerHTML = 'Search';
searchButton.title = 'Search';

searchCtr.className = 'search-container';
searchBox.type = 'search';
results.className = 'search-results';

searchCtr.appendChild(searchBox);
searchCtr.appendChild(searchButton);
searchCtr.appendChild(results);

results.style.display = 'none';

const nav = document.querySelector('nav');

const sib = nav.firstElementChild;
nav.insertBefore(searchCtr, sib);

let lastWords = '';

searchBox.addEventListener('change', handler);
searchBox.addEventListener('input', handler);


let searchTimeout = null;

function stageSearch(text) {
    lastWords = text;
    if (searchTimeout) {
        clearTimeout(searchTimeout);
        searchTimeout = null;
    }
    const url = `/search/${encodeURIComponent(text)}`;

    searchTimeout = setTimeout(() => {
        get(url, (body) => {
            const entries = JSON.parse(body);
            results.innerHTML = '';

            let count = 0;

            count = addResults('Shows', entries, 'gigs', results) +
                addResults('Artists', entries, 'artists', results) +
                addResults('Venues', entries, 'venues', results);

            if (! count) {
                results.innerHTML = 'No matches found';
            }

            showResultsBox();
        });
    }, 250);
}

function showResultsBox() {
    results.style.display = 'block';

    const boxPos = results.getBoundingClientRect();
    const winWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const winHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    const boxWidth = Math.min(400, winWidth - boxPos.x - 10);
    const boxHeight = Math.min(1000, winHeight - boxPos.y - 10);

    results.style.height = `${boxHeight}px`;
    results.style.width = `${boxWidth}px`;
}

function handler() {
    const text = searchBox.value.trim();

    if (text) {
        if (text !== lastWords) {
            stageSearch(text);
        }
    }
    else {
        dropSearchResults();
    }
}

function addResults(groupname, allEntries, key, container) {
    const entries = allEntries[key];

    if (entries.length > 0) {
        const header = document.createElement('h4');
        header.className = 'search-header';
        header.innerHTML = groupname;
        container.appendChild(header);

        entries.forEach((entry) => {
            const link = `/${key}/${entry.slug}`;
            const title = entry.name || entry.title;
            const a = document.createElement('a');
            a.href = link;
            const tn = document.createTextNode(title);
            a.appendChild(tn);
            a.className = 'search-result';

            if (entry.nice_day) {
                const sp = document.createElement('span');
                sp.innerHTML = entry.nice_day;
                sp.className = 'search-date';
                a.appendChild(sp);
            }
            container.appendChild(a);
        });
    }

    return entries.length;
}

function dropSearchResults() {
    results.innerHTML = '';
    results.style.display = 'none';
    lastWords = '';
}
